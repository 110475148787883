<template>
  <div v-if="!(pano.isPro&&pano.hideAround)">
    <template v-if="aroundPanos.length>0">
      <div class="text-shadow" style="margin-left:5px">
        <span v-if="pano.gpsLat!=0||pano.gpsLng!=0">附近全景</span>
        <span v-else>推荐全景</span>
        <span style="display:none">{{view.Direction}}</span>
      </div>
      <div v-if="hasD" ref="ruler">
        <div class="ruler text-shadow">
          <div class="item" v-for="m in scales" :style="m.style">
            <i v-if="m.text=='I'" class="fas fa-angle-up"></i>
            <span v-else>{{m.text}}</span>
          </div>
        </div>
        <div class="scalesView text-shadow">
          <div class="item" v-for="V in scalesView" :style="V.style">
            <div v-for="m in V.c" class="hotspotItem" style="display:inline-block;margin:5px;pointer-events:auto;vertical-align: top;position:relative;">
              <div class="picback">
              </div>
              <div style="position:relative">
                <nuxt-link :to="getRoute(m.item)" style="color:#fff" :title="m.item.title||'未命名'">
                  <span v-if="pano.gpsLat!=0||pano.gpsLng!=0" class="text-shadow badge">{{getDistance(pano.gpsLat,pano.gpsLng,m.item.gpsLat,m.item.gpsLng)}}</span>
                  <!--<span v-if="pano.gpsLat!=0||pano.gpsLng!=0" class="text-shadow badge">{{m.item.angle}}</span>-->
                  <div style="text-align:center;font-size:0;margin: 2px;">
                    <div style="width:50px;height:50px;background-color:#ccc;display: inline-block;background-position:center;background-size:cover" :style="makeUrlStyle(((m.item.firstImage||{url:''}).url||'').replace('/0/0/0/0/', '/200/0/0/0/'))">
                    </div>
                  </div>
                  <div class="text-truncate" style="text-align:center;font-size:8px;position:absolute;bottom: 0px;left: 0;right: 0;">
                    {{m.item.title||'未命名'}}
                  </div>
                </nuxt-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--<div class="text-shadow ruler" ref="ruler" style="height:64px;overflow:hidden">
        <div class="scale" v-for="m in scales" :style="m.style">
          <div class="hotspotItem" style="display:inline-block;margin:5px;pointer-events:auto;vertical-align: top;position:relative;">
            <div class="picback">
            </div>
            <div style="position:relative">
              <nuxt-link :to="getRoute(m.item)" style="color:#fff" :title="m.item.title||'未命名'">
                <span v-if="pano.gpsLat!=0||pano.gpsLng!=0" class="text-shadow badge">{{getDistance(pano.gpsLat,pano.gpsLng,m.item.gpsLat,m.item.gpsLng)}}</span>-->
      <!--<span v-if="pano.gpsLat!=0||pano.gpsLng!=0" class="text-shadow badge">{{m.item.angle}}</span>-->
      <!--<div style="text-align:center;font-size:0;margin: 2px;">
                  ty
                  <div style="width:50px;height:50px;background-color:#ccc;display: inline-block;background-position:center;background-size:cover" :style="makeUrlStyle(((m.item.firstImage||{url:''}).url||'').replace('/0/0/0/0/', '/200/0/0/0/'))">
                  </div>
                </div>
                <div class="text-truncate" style="text-align:center;font-size:8px;position:absolute;bottom: 0px;left: 0;right: 0;">
                  {{m.item.title||'未命名'}}
                </div>
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>-->
      <div v-if="!hasD" class="no-scrollbar text-shadow background" style="">
        <ul class="list-unstyle">
          <li v-for="(item,index) in aroundPanos" class="hotspotItem" style="display:inline-block;margin:5px;pointer-events:auto;vertical-align: top;position:relative;">
            <div class="picback">
            </div>
            <div style="position:relative">
              <nuxt-link :to="getRoute(item)" style="color:#fff" :title="item.title||'未命名'">
                <span v-if="pano.gpsLat!=0||pano.gpsLng!=0" class="text-shadow badge">{{getDistance(pano.gpsLat,pano.gpsLng,item.gpsLat,item.gpsLng)}}</span>
                <!--<span v-if="pano.gpsLat!=0||pano.gpsLng!=0" class="text-shadow badge">{{item.angle}}</span>-->
                <div style="text-align:center;font-size:0;margin: 2px;">
                  <div style="width:50px;height:50px;background-color:#ccc;display: inline-block;background-position:center;background-size:cover" :style="makeUrlStyle(((item.firstImage||{url:''}).url||'').replace('/0/0/0/0/', '/200/0/0/0/'))">
                  </div>
                </div>
                <div class="text-truncate" style="text-align:center;font-size:8px;position:absolute;bottom: 0px;left: 0;right: 0;">
                  {{item.title||'未命名'}}
                </div>
              </nuxt-link>
            </div>
          </li>
        </ul>
      </div>
    </template>
  </div>
</template>
<script>
  import routeTemplate from '../../../libs/routeTemplate'
  import gpsCorrect from '../../../libs/gpsCorrect.js'
  import { forIn } from 'lodash'
  export default {
    components: {
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    data() {
      return {
        hasP: false,
        hasD: true,
        aroundPanos: [],
        loading: false,
        view: {},
        scales: [],
        scalesView: [],
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getComonent: {
        default: {}
      },
      getFunc: {}
    },
    computed: {
      pano() {
        return this.publicData.pano || {}
      },
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        events: {
        },
        funcs: {
          ViewChange: this.ViewChange,
        }
      })
      this.loadAroundPanos()
    },
    destroyed() {
    },
    methods: {
      loadAroundPanos() {
        if (process.client) {
          if (this.pano.gpsImgDirection == null) {
            this.hasD = false
          }
          if (this.pano.gpsLng != 0 || this.pano.gpsLat != 0) {
            this.hasP = true
            this.loading = true
            var url = process.env.EnableElasticSearch ? `/Api/ES/Panos` : `/Api/Panos/AroundPanos`
            this.$axios.get(url, {
              params: {
                page: 1,
                pageSize: 11,
                lng: this.pano.gpsLng,
                lat: this.pano.gpsLat,
                radius: 100000,
                notInn: true,
                ...this.config.params
              }
            }).then((res) => {
              this.aroundPanos = res.data.data
              for (var i in this.aroundPanos) {
                var item = this.aroundPanos[i]
                if (item.guid == this.pano.guid) {
                  this.aroundPanos.splice(i, 1)
                  break
                }
              }
              for (var i in this.aroundPanos) {
                var item = this.aroundPanos[i]
                this.aroundPanos[i].distance = this.getDistance(this.pano.gpsLat, this.pano.gpsLng, item.gpsLat, item.gpsLng)
                this.aroundPanos[i].angle = this.getAngle(this.pano.gpsLat, this.pano.gpsLng, item.gpsLat, item.gpsLng)
              }
              this.loading = false
              this.ViewChange()

            })
          } else {
            this.loading = true
            var url = process.env.EnableElasticSearch ? `/Api/ES/Panos` : `/Api/Panos`
            this.$axios.get(url, {
              params: {
                page: 1,
                pageSize: 11,
                notInn: true,
                fine: true,
              }
            }).then((res) => {
              this.aroundPanos = res.data.data
              for (var i in this.aroundPanos) {
                var item = this.aroundPanos[i]
                if (item.guid == this.pano.guid) {
                  this.aroundPanos.splice(i, 1)
                  break
                }
              }
              this.loading = false
            })
          }
        }
      },
      getRoute(item) {
        if (this.config && this.config.routeTemplate) {
          return routeTemplate(this.config.routeTemplate)(item)
        }
        return { name: 'PanoView', params: { panoId: item.key } }
      },
      getDistance(lat1, lng1, lat2, lng2) {
        var km = gpsCorrect.getDistance(lat1, lng1, lat2, lng2)
        if (km < 1) {
          return `${(km * 1000).toFixed(0)}m`
        }
        if (km < 10) {
          var str = `${(km).toFixed(1)}km`
          str = str.replace('.0', '')
          return str
        }
        return `${(km).toFixed(0)}km`
      },
      getAngle(lat1, lng1, lat2, lng2) {
        var a = gpsCorrect.getAngle(lat1, lng1, lat2, lng2)
        return `${(a).toFixed(2)}`
      },
      ViewChange() {
        var v = this.getFunc({ target: 'FullPano', name: 'getView' })()
        this.view = {
          fov: Number(Number(v.fov).toFixed(1)),
          hLookAt: Number(this.math360(Number(v.hlookat)).toFixed(2)),
          vLookAt: Number(Number(v.vlookat).toFixed(2)),
        }
        if (this.hasD) {
          this.view.Direction = Number(this.math360(this.view.hLookAt - this.pano.gpsImgDirection).toFixed(2))
          this.renewMarks()
        }
      },
      renewMarks() {
        if (!this.$refs.ruler) {
          return
        }
        var scales = []
        var scalesView = []
        var hl = this.math360(Number(this.view.hLookAt)) - (this.pano.gpsImgDirection ?? 0)
        var r = this.view.fov / this.$refs.ruler.offsetWidth
        var s = hl - this.view.fov / 2
        var e = hl + this.view.fov / 2
        //this.getd(55, 55, 50, s, e)
        scales.push({
          style: {
            left: `${(this.math360(0, hl) - s) / r}px`
          },
          text: '北'
        })
        scales.push({
          style: {
            left: `${(this.math360(-45, hl) - s) / r}px`
          },
          text: '西北'
        })
        scales.push({
          style: {
            left: `${(this.math360(-90, hl) - s) / r}px`
          },
          text: '西'
        })
        scales.push({
          style: {
            left: `${(this.math360(45, hl) - s) / r}px`
          },
          text: '东北'
        })
        scales.push({
          style: {
            left: `${(this.math360(90, hl) - s) / r}px`
          },
          text: '东'
        })
        scales.push({
          style: {
            left: `${(this.math360(135, hl) - s) / r}px`
          },
          text: '东南'
        })
        scales.push({
          style: {
            left: `${(this.math360(180, hl) - s) / r}px`
          },
          text: '南'
        })
        scales.push({
          style: {
            left: `${(this.math360(-135, hl) - s) / r}px`
          },
          text: '西南'
        })

        var svw = 64//显示单个的宽度单位像素
        for (var i in this.aroundPanos) {
          var ap = this.aroundPanos[i]
          var lpx = (this.math360(ap.angle, hl) - s) / r
          scales.push({
            style: {
              left: `${lpx}px`
            },
            text: 'I',
            item: ap,
          })
          scalesView.push({
            ag: Number(ap.angle),
            c: [{
              p: lpx,
              ag: Number(ap.angle),
              item: ap,
            }]
          })
        }
        var stack = [...scalesView]//等待聚合栈
        var group = []
        var l = svw * r
        while (stack.length > 0) {
          var d = stack.pop()
          var hasg = false
          for (var i in group) {
            if (this.checkD(d, group[i], l)) {//如果需要聚合就合成新聚合并入栈
              hasg = true
              var cs = [...d.c, ...group[i].c]
              let all = 0
              var n = cs[0].ag
              for (var k in cs) {
                all += this.math360(cs[k].ag, n)
              }
              var ag = all / cs.length
              stack.push({
                ag: ag,
                c: cs,
              })
              group.splice(i, 1)
              break
            }
          }
          if (!hasg) {//没有聚合则直接插入
            group.push(d)
          }
        }
        for (var i in group) {
          group[i].style = {
            left: `${((this.math360(group[i].ag, hl) - s) / r) - (group[i].c.length * svw / 2)}px`
          }
          group[i].c.sort((a, b) => a.ag - b.ag)
        }
        //scalesView.sort((a, b) => a.p - b.p)
        //for (var i = scalesView.length - 1; i - 1 >= 0; i--) {
        //  if (scalesView[i].p - scalesView[i - 1].p < svw * (scalesView[i].c.length + scalesView[i - 1].c.length) / 2) {
        //    scalesView[i - 1].c.push(...scalesView[i].c)

        //    let all = 0
        //    for (var k in scalesView[i - 1].c) {
        //      all += scalesView[i - 1].c[k].p
        //    }
        //    scalesView[i - 1].p = all / scalesView[i - 1].c.length

        //    scalesView[i - 1].style.left = `${scalesView[i - 1].p - (svw * scalesView[i - 1].c.length) / 2}px`
        //    scalesView.splice(i, 1)

        //  }
        //}
        this.scales = scales
        this.scalesView = group
      },
      checkD(a, b, l) {//判别角度距离差
        var d = Math.abs(this.angle_diff(a.ag, b.ag))
        return d < l * (a.c.length + b.c.length) / 2
      },
      angle_diff(a, b) {//计算角度差
        var d1 = a - b
        var d2 = 360 - Math.abs(d1)
        if (d1 > 0)
          d2 = -d2
        if (Math.abs(d1) < Math.abs(d2))
          return d1
        else
          return d2
      },
      math360(i, n) {
        //n ??= 180
        if (n == undefined) {
          n = 180
        }
        while (i < n - 180 || i >= n + 180) {
          i = i > n ? i - 360 : i + 360
          i = Number(Number(i).toFixed(4))
        }
        return i
      },
      makeUrlStyle(url) {
        return {
          'background-image': "url('" + url + "')"
        }
      },
    },
  }
</script>
<style scoped>
  .background {
    white-space: nowrap;
    font-size: 14px;
    margin: 0;
    overflow: auto;
    opacity: 0.8;
    background-color: #0005
  }

  .ruler {
    position: relative;
    height: 20px;
  }

    .ruler .item {
      position: absolute;
      white-space: nowrap;
      width: 2.5em;
      margin-left: -1.25em;
      text-align: center;
    }

  .scalesView {
    position: relative;
    height: 65px;
  }

    .scalesView .item {
      position: absolute;
      white-space: nowrap;
    }

  .badge {
    display: inline-block;
    padding: 0.25em;
    font-size: 8px;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    position: absolute;
    top: 0;
    right: 0;
  }

  .picback {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #fffa;
    box-shadow: #0005 2px;
    box-shadow: #000 0 0 2px;
  }

    .picback.active {
      background-color: #ffbb00aa;
    }
</style>
